.list-group-item>i.fas{
    color: #ffd200;
}
.title{
    font-weight: 800;
    font-size: 20px;
}
.title2{
    color: #4f4f4e;
    font-size: 18px;
}
.bar{
    top: 10px;
    left: 3%;
}
.contain{
    min-height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
}
#sideNav{
    min-height: 100vh;
    min-width: 20vw;
    max-width: 20vw;
    background-color: #333333!important;
    padding: 10px 20px;
}
#sideNav.active {
    margin-left: -20vw;
}
#mainContent{
    min-height: 100vh;
/*    width: 80vw;*/
    background-color: #efefef!important;
}
#contentDash{
    width: 100%;
    padding: 5%;
}
.cardBox{
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 10px;
}
.cardP{
    color: #262626;
    font-weight: bold;
    font-size: 18px;
     margin-bottom: 0;
}



#transaction{
    background-color: #fff;
    width: 100%;
    min-height: 100vh;    
}
#topNav{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #333333!important;
    width: 70vh;
    height: 12vh;
}
#topNav1{ 
    padding: 10px;
    text-align: center;
}
#topNav2{
    background-color: #ffd200!important;
    width: 100%;
    height: 12vh;   
    padding: 10px;
    text-align: center;
}
#image2 {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
}
.cad{
    background-color: #fff; 
    padding: 10%;
    margin-bottom: 15px;
    border-radius: 10px;
}
#cad1{
    background-color: #ffd200!important; 
}
.cad>p{
    font-weight: 600;
     margin-bottom: 0.5px;
    color: #4f4f4e;
}
.cad>span{
    font-weight: 800;
}
#navBottom{
    margin-top: 20vh;
}
.list-group-horizontal{
    flex-direction: row;
 }
.list-group-horizontal .list-group-item{
    padding: 0px!important;
    border: 1px solid transparent;
    margin-right: 20px;
 }
#sideNav .list-group-flush {
    margin-top: 20px;
}
#sideNav .list-group-item {
    background-color: transparent;
    border-bottom: 1px solid #444444;
    color: #fff;
}
#sideNav .list-group-item:last-child {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    color: #fff;
}
.agentCad{ 
    background-color: #fff; 
    padding: 5% 5px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.agentCad:hover{ 
    background-color: #ffd200;
    color: #000;
}
#agentImg{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
}
.p {
    font-weight: 600;
    margin: 0px;
    color: #4f4f4e;
    text-align: center;
    font-size: 15px;
}
.p:hover {
    color: #4f4f4e;    
}
.p1 {
    font-weight: 600;
    margin: 0px;
    color: #4f4f4e;
    text-align: center;
    font-size: 12px;
}
.li{
    background-color:#7a7a79; color:#000;
    
}
.search .form-control {
    display: block;
    width: 100%; 
    border: 1px solid #bdbdbd!important;
    padding: 2px 5px;
    color: #495057;
    background-color: #ededed!important;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: 40px!important;
}
.breadcrumb {
    border-radius: 0;
    background: none !important;
    color: #000;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    padding: 11px 0;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}
.breadcrumb>li {
    display: inline-block;
    margin-left: 15px;
    padding: 5px 15px!important;
    border-radius: 8px; 
    font-weight: 500;
}
.breadcrumb>li a{
    color: #000;
}



@media (max-width: 1025px) {
   
    #sideNav{
      margin-left: -20vw;
    }
/*
    #mainContent{
       display: block;
        width: 100vw;
    }
*/
/*    F
.search .form-control {
    margin: 0px!important;
}*/
    
}
    
@media (max-width: 700px) {

    #sideNav{
        margin-left: -20vw;
    }
/*
    #mainContent{
       display: block;
        width: 100vw;
    }
*/
    #sidebar.active {
        margin-left: 0;
    }
    
.search .form-control {
    margin: 0px!important;
}
    
}







