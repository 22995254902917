#content{
    background-color: #fff!important;
}
#HomeContent {
    background-color: #efefef!important;
    width: 100%;
    padding: 0;
    min-height: 100vh;
    transition: all 0.3s;
}
#sidebar {
    min-width: 270px;
    max-width: 270px;
    background: #333333!important;
    color: #fff;
    transition: all 0.3s;
    position: relative;
}
#sidebar ul li a span {
    color: #ffd200;
}
input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime-local], input[type=tel], input[type=number], input[type=search-md], input[type=search], textarea.md-textarea {
    background-color: #fff;
    border: 1px solid #bdbdbd;
}
  
.btn-warn {
    background-color: #ffd200!important;
}
.white-text, .btn {
    color: #000 !important;
}
#sidebar .custom-menu .btn.btn-primary {
    background: #333333!important;
    border-color: #333333!important;
    z-index: 10001;
}
#sidebar .custom-menu .btn.btn-primary:after {
    background: #333333;
    z-index: 10001;
}
.sr-only{
    z-index: 11001;
}
#sidebar .custom-menu .btn i {
    margin-right: -40px;
    font-size: 14px;
    z-index: 11001;
}
#topNav {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #333333!important;
    width: 70vh;
    height: 12vh;
}
#topNav1 {
    padding: 10px;
    text-align: center;
}
#image2 {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
}
#topNav2 {
    background-color: #ffd200!important;
    width: 100%;
    height: 12vh;
    padding: 10px;
    text-align: center;
}
#transaction {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
}
#navBottom {
    margin-top: 20vh;
}
#transaction {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
}
#cad1 {
    background-color: #ffd200!important;
}

.cad {
    background-color: #fff;
    padding: 10%;
    margin-bottom: 15px;
    border-radius: 10px;
}
.list-group-horizontal .list-group-item {
    padding: 0px!important;
    border: 1px solid transparent;
    margin-right: 20px;
}
.title {
    font-weight: 800;
    font-size: 15px;
    color:#000;
}
.cad>span {
    font-weight: 500;
}
.cad>i {
    color:#000;
}
.cad>p {
    color:#000;
}
.cad>span {
    color:#000;
    font-weight: 800;
}

.li{
    background-color:#7a7a79; color:#000;
    
}
.search .form-control {
    display: block;
    width: 100%; 
    border: 1px solid #bdbdbd!important;
    padding: 2px 5px;
    color: #495057;
    background-color: #fff;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: 40px!important;
}
.breadcrumb-box{
    width: 100%;
}
.breadcrumb {
    border-radius: 0;
    background: none !important;
    color: #000;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    padding: 11px 0;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}
.breadcrumb>li {
    display: inline-block;
    margin-left: 15px;
    padding: 5px 15px!important;
    border-radius: 8px; 
    font-weight: 500;
}
.breadcrumb>li a{
    color: #000;
}
.Tactive {
    background-color: #ffd200;
    padding: 2px 10px;
    border-radius: 10px;
}
.pending {
    background-color: #f1540b;;
    padding: 2px 10px;
    border-radius: 10px;
}
.success {
    background-color: #0c593b;
    color: #ededed;
    padding: 2px 10px;
    border-radius: 10px;
}
h6.profile{
    margin-bottom: .05rem!important;
}
#ProfileSidebar ul li {
    padding: 0px 10px;
    font-size: 1rem; 
    color: #4a4a4a!important;
}
#ProfileSidebar ul li a {
    color: #4a4a4a!important;
}

